import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/celebrity.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/custom-choice.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/detox.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/fish-free.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/fish.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/for-moms.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/gluten-free.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/hashimoto.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/high-protein.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/immunity.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/intermittent-fasting.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/keto.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/lactose-free.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/low-carbohydrate.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/low-fat.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/low-glycemic-index.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/medical.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/paleo.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/reductive.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/sirt.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/sports.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/standard.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/vegan.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/diet-types/vegetarian.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/minus.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/multidiet-homepage.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/assets/icons/plus.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/components/block-renderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/components/common/loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMain"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/components/header/header-main.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BreadcrumbsGeneric"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/breadcrumbs/components/breadcrumbs-generic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizerForm"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/localizer/components/localizer-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroLandingVideo"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/static-builder/components/hero-landing/hero-landing-video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroDietsCarousel"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/static-builder/components/hero/hero-diets-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/static-builder/components/multidiet-cta/multidiet-cta-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/home/runner/work/marketplace/marketplace/apps/web/src/features/static-builder/components/newsletter/newlsetter-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_kaabgtn2ip4jsa5dtevrxmqwtm/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_kaabgtn2ip4jsa5dtevrxmqwtm/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.4_kaabgtn2ip4jsa5dtevrxmqwtm/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_blx3vtg2vynxkkltyvtbrxvham/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_blx3vtg2vynxkkltyvtbrxvham/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/marketplace/marketplace/node_modules/.pnpm/react-fast-marquee@1.6.5_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/home/runner/work/marketplace/marketplace/packages/ui/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/marketplace/marketplace/packages/ui/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/home/runner/work/marketplace/marketplace/packages/ui/components/ui/separator.tsx");
