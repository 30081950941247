'use client';

import { type BreadCrumbsType } from '../types/breadcrumbs.type';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Link, getLocaleForPathname, getPathname } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import React, { type HTMLAttributes } from 'react';

type BreadcrumbsProps = HTMLAttributes<HTMLDivElement> & {
  data: BreadCrumbsType[];
  linkClassName?: string;
};

export const BreadcrumbsGeneric = ({
  data,
  className,
  linkClassName,
  ...props
}: BreadcrumbsProps) => {
  const t = useTranslations('breadcrumbs');
  const locale = getLocaleForPathname();

  return (
    <Breadcrumb className={cn('relative z-10', className)} {...props}>
      <BreadcrumbList className='flex-nowrap items-center overflow-hidden text-base'>
        <BreadcrumbItem className='flex-initial'>
          <BreadcrumbLink asChild>
            <Link
              href={getPathname({ href: '/', locale })}
              title={t('home')}
              className={cn(
                'inline-block overflow-hidden text-nowrap text-white opacity-60 hover:text-white hover:opacity-100',
                linkClassName,
              )}
            >
              {t('home')}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {data.map((item, index) => {
          return (
            <React.Fragment key={item.label}>
              <BreadcrumbSeparator
                className={cn('text-white opacity-60', linkClassName)}
              >
                /
              </BreadcrumbSeparator>
              <BreadcrumbItem className='inline-block flex-initial text-nowrap'>
                {index + 1 === data.length ? (
                  <BreadcrumbPage className={cn('text-white', linkClassName)}>
                    {data ? item.label : t(item.label)}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink
                    className={cn(
                      'text-nowrap text-white opacity-60',
                      linkClassName,
                    )}
                    asChild
                  >
                    <Link href={item.url}>
                      {data ? item.label : t(item.label)}
                    </Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
